import React, { createContext, useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import './App.css';
import { PrivateRoute } from './components/PrivateRoute';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import axios from 'axios';
import { UserDTO } from './types/User';
import { getCurrentUser } from './services/AuthService';
import { Trial } from './pages/Trial';

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error.response);
  }
);

export const UserContext = createContext<UserDTO | undefined>(undefined);

function App() {
  const [user, setUser] = useState<UserDTO>();
  const location = useLocation();

  useEffect(() => {
    if (
      location &&
      !['/login', '/trial', '/login/', '/trial/'].includes(location.pathname) &&
      !user
    ) {
      Promise.all([handleCurrentUser()]);
    }
  }, [location, user]);

  const onLogin = async () => {
    await handleCurrentUser();
  };

  const handleCurrentUser = async () => {
    await getCurrentUser()
      .then((res) => {
        setUser(res);
      })
      .catch(() => {
        localStorage.removeItem('token');
        window.location.href = '/login';
      });
  };

  return (
    <div className="App">
      <Switch>
        <Route path="/login">
          <Login onLogin={() => onLogin()} />
        </Route>
        <Route path="/trial">
          <Trial></Trial>
        </Route>
        <PrivateRoute path="/">
          <UserContext.Provider value={user}>
            <Home />
          </UserContext.Provider>
        </PrivateRoute>
      </Switch>
    </div>
  );
}

export default App;
